<template>
  <v-container class="px-0 mt-4">
    <div class="import-card mb-5">
      <v-row class="px-4 mb-3">
        <v-col cols="7">
          <span class="step-text-head">เลขที่งาน</span>
          <br />
          <span class="step-text-body">{{ form.order_id }}</span>
        </v-col>
        <v-col cols="5">
          <span class="step-text-head">Order-Driver</span>
          <br />
          <span class="step-text-body">{{ form.job_no }}</span>
        </v-col>
        <v-col cols="12">
          <span class="step-text-head">ชื่อบริษัทลูกค้า</span>
          <br />
          <span class="step-text-body">{{ form.customer }}</span>
        </v-col>
        <v-col cols="12">
          <span class="step-text-head">วันที่และเวลา</span>
          <br />
          <span class="step-text-body"
            >{{ form.load_unload_date | formatDate }}
            {{ form.load_unload_time | formatTime }}</span
          >
        </v-col>
      </v-row>
      <v-divider class="mb-5" />
      <v-row class="px-4 mb-3">
        <v-col cols="7">
          <span class="text-title-work">ต้นทาง</span>
          <br />
          <span class="step-text-body-black">{{ form.release_port }}</span>
        </v-col>
        <v-col cols="5">
          <span class="text-title-work">เวลารับ</span>
          <br />
          <span class="step-text-body-black">00:00</span>
        </v-col>
        <v-col cols="7">
          <span class="text-title-work">ส่ง/รับ</span>
          <br />
          <span class="step-text-body-black">{{ form.wh_factory }}</span>
        </v-col>
        <v-col cols="5">
          <span class="text-title-work">เวลาส่ง</span>
          <br />
          <span class="step-text-body-black">{{
            form.load_unload_time | formatTime
          }}</span>
        </v-col>
        <v-col cols="7">
          <span class="text-title-work">คืนตู้</span>
          <br />
          <span class="step-text-body-black">{{
            form.return_place_import
          }}</span>
        </v-col>
        <v-col cols="5">
          <span class="text-title-work">เวลาคืนตู้</span>
          <br />
          <span class="step-text-body-black">20:00</span>
        </v-col>
      </v-row>
      <v-divider class="mb-2" style="background: #59cb33"></v-divider>
      <v-row>
        <v-col class="text-center" cols="4">
          <span class="text-title-work">ขนาด</span>
          <br />
          <span class="text-foot-list-work">{{ form.container_size }}’</span>
        </v-col>
        <v-divider
          vertical
          style="
            background: #59cb33;
            height: 40px;
            position: relative;
            top: 20px;
          "
        ></v-divider>
        <v-col class="text-center" cols="4">
          <span class="text-title-work">Isotype</span>
          <br />
          <span class="text-foot-list-work">{{ form.isotype }}</span>
        </v-col>
        <v-divider
          vertical
          style="
            background: #59cb33;
            height: 40px;
            position: relative;
            top: 20px;
          "
        ></v-divider>
        <v-col class="text-center" cols="4">
          <span class="text-title-work">จำนวน</span>
          <br />
          <span class="text-foot-list-work">1</span>
          <!-- <span class="text-foot-list-work">{{ form.qty }}</span> -->
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col cols="12">
        <googlemap
          v-if="
            markers_current &&
            markers_current.lat &&
            markers_current.lng &&
            markers_place.lat &&
            markers_place.lng
          "
          :markers_current="markers_current"
          :markers_place="markers_place"
          :radius="form.release_port_radius"
        />
        <v-card outlined v-else>
          <!-- no map -->
          <v-card-text class="text-center">
            <v-icon class="mb-2" color="grey lighten-1" size="60">
              mdi-map-marker-off
            </v-icon>
            <div class="font-24 grey--text text--darken-1">
              ไม่พบข้อมูลพิกัด
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" class="pt-4">
        <v-btn
          class="text-center"
          color="#E5E5E5"
          style="border-radius: 15px; width: 100%; height: 60px"
          @click="accept('REJECT')"
        >
          <span style="color: #828282; font-size: 20px; font-weight: bold"
            >ปฏิเสธ</span
          >
        </v-btn>
      </v-col>
      <v-col cols="8" class="pt-3">
        <v-btn
          class="text-center"
          color="#59CB33"
          style="border-radius: 15px; width: 100%; height: 65px"
          @click="accept('ACCEPT')"
        >
          <img class="mr-3" src="@/assets/image/step1-import.png" />
          <span style="color: #ffffff; font-size: 36px; font-weight: bold"
            >รับงาน</span
          >
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Googlemap from "@/components/googlemap";
export default {
  name: "step1-import",
  props: {
    form: Object,
  },
  components: { Googlemap },
  data() {
    return {
      markers_place: null,
      markers_current: null,
    };
  },
  created() {
    this.markers_place = {
      lat: parseFloat(this.form.release_port_latitude),
      lng: parseFloat(this.form.release_port_longitude),
    };
  },
  mounted() {
    this.geolocation();
  },
  methods: {
    geolocation() {
      this.globalLoading();
      navigator.geolocation.getCurrentPosition((position) => {
        this.markers_current = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.globalHideLoading();
      });
    },
    accept(val) {
      let data = {
        action: val,
        step: 2,
      };
      this.$emit("nextStep", data);
    },
  },
};
</script>

<style scoped></style>
