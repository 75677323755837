<template>
  <v-container class="px-0 pt-6">
    <div class="import-card mb-5">
      <v-layout class="pa-4" wrap>
        <v-flex xs6>
          <div class="step-text-head">เลขที่อ้างอิง</div>
          <div class="step-text-body" style="word-break: break-word">
            {{ form.inv_po }}
          </div>
        </v-flex>
        <v-flex xs6>
          <div class="step-text-head">สถานที่ส่งสินค้า</div>
          <div class="step-text-body" style="word-break: break-word">
            {{ form.wh_factory }}
          </div>
        </v-flex>
        <v-flex xs6>
          <div class="step-text-head">วันเวลาส่งสินค้า</div>
          <div class="step-text-body">
            {{ form.load_unload_date | formatDate }}
            <br />
            {{ form.load_unload_time | formatTime }}
          </div>
        </v-flex>
        <v-flex xs6>
          <div class="step-text-head">วันเวลาปัจจุบัน</div>
          <div class="step-text-body">
            {{ formatDateDate(new Date()) }}
            <br />
            {{ formatDateTime(new Date()) }}
          </div>
        </v-flex>
        <v-flex xs12>
          <div class="step-text-head">คำสั่งพิเศษ</div>
          <div class="step-text-body">{{ form.remark }}</div>
        </v-flex>
      </v-layout>
    </div>
    <v-row>
      <v-col cols="12">
        <googlemap
          v-if="
            markers_current &&
            markers_current.lat &&
            markers_current.lng &&
            markers_place.lat &&
            markers_place.lng
          "
          :markers_current="markers_current"
          :markers_place="markers_place"
          :radius="form.wh_factory_radius"
        />
        <v-card outlined v-else>
          <!-- no map -->
          <v-card-text class="text-center">
            <v-icon class="mb-2" color="grey lighten-1" size="60">
              mdi-map-marker-off
            </v-icon>
            <div class="font-24 grey--text text--darken-1">
              ไม่พบข้อมูลพิกัด
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-stepper
          v-model="e6"
          class="pb-0"
          vertical
          style="background: #f5f5f5; box-shadow: none"
        >
          <v-stepper-step
            class="px-0 pb-2"
            :complete="e6 > 1"
            color="#59CB33"
            step="1"
          >
            <span style="color: #59cb33; font-size: 16px">ถึงโรงงาน</span>

            <v-btn
              v-if="e6 == 1"
              color="#59CB33"
              height="55px"
              style="
                border-radius: 15px;
                position: absolute;
                right: 0;
                top: 10%;
              "
              @click="statusStep(2)"
            >
              <img class="mr-4" src="@/assets/image/arrow-circle-right.png" />
              <span style="color: #ffffff">ถึงโรงงาน</span>
            </v-btn>
          </v-stepper-step>

          <div class="pl-5">
            <v-row>
              <v-col cols="1" class="pl-0" style="height: 80px">
                <v-divider
                  :style="{ background: e6 >= 2 ? '#59CB33' : '' }"
                  style="position: relative; top: 0; left: 30%"
                  vertical
                />
              </v-col>
              <v-col cols="11">
                <template v-if="e6 >= 2">
                  <span class="step-text-import">
                    {{ convertFormatDate(status1.date) }}
                  </span>
                  <br />
                  <span class="step-text-import">
                    {{ status1.position }}
                  </span>
                </template>
              </v-col>
            </v-row>
          </div>

          <v-stepper-step
            class="px-0"
            :complete="e6 > 2"
            color="#59CB33"
            step="2"
          >
            <span
              style="font-size: 16px"
              :style="{ color: e6 >= 2 ? '#59CB33' : '' }"
              >เริ่มลงสินค้า</span
            >
            <v-btn
              v-if="e6 == 2"
              color="#59CB33"
              height="55px"
              style="
                border-radius: 15px;
                position: absolute;
                right: 0;
                top: 10%;
              "
              @click="statusStep(3)"
            >
              <img class="mr-4" src="@/assets/image/arrow-circle-right.png" />
              <span style="color: #ffffff">เริ่มลงสินค้า</span>
            </v-btn>
          </v-stepper-step>

          <div class="pl-5">
            <v-row>
              <v-col cols="1" class="pl-0" style="height: 80px">
                <v-divider
                  :style="{ background: e6 >= 3 ? '#59CB33' : '' }"
                  style="position: relative; top: 0; left: 30%"
                  vertical
                />
              </v-col>
              <v-col cols="11">
                <template v-if="e6 >= 3">
                  <span class="step-text-import">
                    {{ convertFormatDate(status2.date) }}
                  </span>
                  <br />
                  <span class="step-text-import">
                    {{ status2.position }}
                  </span>
                </template>
              </v-col>
            </v-row>
          </div>

          <v-stepper-step
            class="px-0"
            :complete="e6 > 3"
            color="#59CB33"
            step="3"
          >
            <span
              style="font-size: 16px"
              :style="{ color: e6 >= 3 ? '#59CB33' : '' }"
              >ลงสินค้าเสร็จสิ้น</span
            >
          </v-stepper-step>

          <v-stepper-content step="3" class="ma-0 pa-0">
            <v-row class="mt-3">
              <v-col cols="6">
                <span>รูปท้ายตู้</span>
                <br />
                <take-photo @img="saveImageContainer" />
              </v-col>
              <v-col cols="6">
                <span>ใบส่งสินค้า</span>
                <br />
                <take-photo @img="saveGoods" />
              </v-col>
              <v-col cols="12">
                <span>ลายเซ็นผู้รับสินค้า</span>
                <br />
                <VueSignaturePad
                  id="signature"
                  width="100%"
                  height="150px"
                  style="background: #ffffff; border-radius: 15px"
                  ref="signaturePad"
                  :options="{ onEnd }"
                />
              </v-col>
              <v-col class="pt-3 mb-4">
                <v-btn
                  class="text-center"
                  color="#59CB33"
                  style="border-radius: 15px; width: 100%; height: 70px"
                  @click="next"
                >
                  <img
                    class="mr-3"
                    src="@/assets/image/step2-submit-import.png"
                  />
                  <span
                    style="color: #ffffff; font-size: 20px; font-weight: bold"
                    >ส่งข้อมูลและดำเนินการต่อ</span
                  >
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Googlemap from "@/components/googlemap";
import TakePhoto from "@/components/takePhoto";
import moment from "moment";
import { mapState } from "vuex";
export default {
  name: "step3-import",
  components: { TakePhoto, Googlemap },
  props: {
    form: Object,
  },
  data() {
    return {
      markers_place: null,
      markers_current: null,
      // e6: 1,
      options: {
        penColor: "#000000",
      },
      container_image: [],
      goods_image: [],
      signature_check: true,
      signature: "",
    };
  },
  computed: {
    ...mapState({
      e6: (state) => {
        return state.job.statusStep;
      },
      status1: (state) => {
        return state.job.status1;
      },
      status2: (state) => {
        return state.job.status2;
      },
      status3: (state) => {
        return state.job.status3;
      },
    }),
  },
  created() {
    this.markers_place = {
      lat: parseFloat(this.form.wh_factory_latitude),
      lng: parseFloat(this.form.wh_factory_longitude),
    };
  },
  mounted() {
    this.geolocation();
  },
  methods: {
    convertFormatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    geolocation() {
      this.globalLoading();
      navigator.geolocation.getCurrentPosition((position) => {
        this.markers_current = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.globalHideLoading();
      });
    },
    onEnd() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      // if isEmpty true not have data
      this.signature_check = isEmpty;
      this.signature = data;
    },
    next() {
      let data = {
        deliver_container_image: "",
        goods_delivery_image: "",
        recipient_signature: "",
        step: 4,
      };
      if (this.container_image.length > 0)
        data.deliver_container_image = this.container_image[0].image;
      if (this.goods_image.length > 0)
        data.goods_delivery_image = this.goods_image[0].image;
      if (!this.signature_check) data.recipient_signature = this.signature;
      this.$emit("nextStep", data);
    },
    statusStep(val) {
      this.globalLoading();
      if (this.markers_current) {
        if (!this.form.wh_factory_latitude || !this.form.wh_factory_longitude) {
          this.globalHideLoading();
          this.$swal({
            icon: "error",
            title: "ไม่สามารถ Check-in ได้!",
            text: "สถานที่ที่ต้องการ Check-in ไม่ถูกต้องหรือไม่มีอยู่จริง กรุณาตรวจสอบพิกัดของจุดหมายอีกครั้ง",
          });
        } else {
          let marker_place = {
            lat: this.form.wh_factory_latitude,
            lng: this.form.wh_factory_longitude,
          };
          let distant = this.calculateDistance(
            this.markers_current,
            marker_place
          );
          let area = this.form.wh_factory_radius
            ? this.form.wh_factory_radius
            : 500;
          if (distant <= area) {
            let data = {
              driver_job_id: this.form.driver_job_id,
              datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
              latitude: this.markers_current.lat,
              longitude: this.markers_current.lng,
              step: val,
              mode: this.form.mode,
            };
            this.$store.dispatch("job/statusStep", data).then((res) => {
              this.globalHideLoading();
            });
          } else {
            this.globalHideLoading();
            this.$swal({
              title: "ไม่สามารถเช็คอินได้!",
              html: `คุณอยู่ห่างจากสถานที่เลือกไว้เกินกว่า${area}เมตร<br/><small>ห่าง ${distant.toFixed(
                2
              )} เมตร</small>`,
              icon: "error",
              confirmButtonText: "ตกลง",
            });
          }
        }
      } else {
        this.globalHideLoading();
        this.$swal({
          icon: "error",
          title: "ไม่สามารถตรวจสอบตำแหน่งของคุณได้ !",
          text: "กรุณาลองใหม่อีกครั้ง หรือตรวจสอบให้แน่ใจว่าคุณได้ทำการเปิดการใช้งานตำแหน่งของคุณแล้ว",
          showConfirmButton: true,
        });
      }
    },
    calculateDistance(origin, destination) {
      let radlat1 = (Math.PI * origin.lat) / 180;
      let radlat2 = (Math.PI * destination.lat) / 180;
      let theta = origin.lng - destination.lng;
      let radtheta = (Math.PI * theta) / 180;
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515 * 1.609344;
      return dist * 1000; // unit : meter
    },
    saveImageContainer(val) {
      this.container_image = val;
    },
    saveGoods(val) {
      this.goods_image = val;
    },
    formatDateDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatDateTime(date) {
      return moment(date, "HH:mm:ss").format("HH:mm");
    },
  },
};
</script>

<style scoped></style>
