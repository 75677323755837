<template>
  <v-container class="px-0 pt-6">
    <div class="import-card mb-5">
      <v-row class="px-4 mb-3">
        <v-col cols="6">
          <span class="step-text-head">สถานที่</span>
          <br />
          <span class="step-text-body">{{ form.return_place_import }}</span>
        </v-col>
        <v-col cols="6">
          <span class="step-text-head">วันเวลาที่ต้องถึง</span>
          <br />
          <span class="step-text-body">
            {{
              form.datetime_arrive
                ? convertFormatDate(form.datetime_arrive)
                : "-"
            }}
          </span>
        </v-col>
        <v-col cols="12" class="pt-0">
          <span class="step-text-head">คำสั่งพิเศษ</span>
          <br />
          <span class="step-text-body">{{ form.remark }}</span>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col cols="12">
        <googlemap
          v-if="
            markers_current &&
            markers_current.lat &&
            markers_current.lng &&
            markers_place.lat &&
            markers_place.lng
          "
          :markers_current="markers_current"
          :markers_place="markers_place"
          :radius="form.cy_place_radius"
        />
        <v-card outlined v-else>
          <!-- no map -->
          <v-card-text class="text-center">
            <v-icon class="mb-2" color="grey lighten-1" size="60">
              mdi-map-marker-off
            </v-icon>
            <div class="font-24 grey--text text--darken-1">
              ไม่พบข้อมูลพิกัด
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#59CB33"
              v-bind="attrs"
              v-on="on"
              style="border-radius: 15px; height: 30px"
              class="float-right"
            >
              <span style="color: #ffffff; font-size: 12px"
                >ที่อยู่ออกใบเสร็จรับตู้</span
              >
            </v-btn>
          </template>

          <v-card style="border-radius: 15px">
            <v-card-title class="justify-center">
              <span style="font-size: 20px; font-weight: bold"
                >ที่อยู่ออกใบเสร็จรับตู้</span
              >
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="12" class="py-0 mt-6">
                  <span>ชื่อ</span>
                </v-col>
                <v-col cols="12" class="py-0 pb-3">
                  <span class="step-text-body">{{ form.customer }}</span>
                </v-col>
                <v-col cols="12" class="py-0">
                  <span>ที่อยู่</span>
                </v-col>
                <v-col cols="12" class="py-0 pb-3">
                  <span
                    class="step-text-body"
                    v-if="form.receipt_type_step4 == 1"
                    >{{ form.customer_address_step4 }}</span
                  >
                  <span class="step-text-body" v-else
                    >TN LOGISTICS CO.,LTD.</span
                  >
                </v-col>
                <v-col cols="12" class="py-0">
                  <span>เลขเสียภาษี</span>
                </v-col>
                <v-col cols="12" class="py-0 pb-2">
                  <span
                    class="step-text-body"
                    v-if="form.receipt_type_step4 == 1"
                    >{{ form.customer_tax_step4 }}</span
                  >
                  <span class="step-text-body" v-else>0205558021614</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="6">
        <span>รูปท้ายตู้</span>
        <br />
        <take-photo @img="saveImageDone" />
      </v-col>
      <v-col cols="6">
        <span>ใบส่งสินค้า</span>
        <br />
        <take-photo @img="saveImageReturn" />
      </v-col>
      <v-col cols="6">
        <span>ใบเสร็จค่าคืนตู้</span>
        <br />
        <take-photo @img="saveImageReceipt" />
      </v-col>
      <v-col cols="12" class="pt-3 mb-4">
        <v-btn
          class="text-center"
          color="#59CB33"
          style="border-radius: 15px; width: 100%; height: 70px"
          @click="finish"
        >
          <img class="mr-3" src="@/assets/image/correct.png" />
          <span style="color: #ffffff; font-size: 24px; font-weight: bold"
            >เสร็จสิ้น</span
          >
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Googlemap from "@/components/googlemap";
import TakePhoto from "@/components/takePhoto";
export default {
  name: "step4-import",
  components: { TakePhoto, Googlemap },
  props: {
    form: Object,
  },
  data() {
    return {
      markers_place: null,
      markers_current: null,
      dialog: false,
      job_done: [],
      container_return: [],
      container_receipt: [],
    };
  },
  created() {
    this.markers_place = {
      lat: parseFloat(this.form.cy_place_latitude),
      lng: parseFloat(this.form.cy_place_longitude),
    };
  },
  mounted() {
    this.geolocation();
  },
  methods: {
    geolocation() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.markers_current = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    finish() {
      let data = {
        job_done_container_image: "",
        container_return_image: "",
        container_return_receipt_image: "",
        step: 5,
      };
      if (this.job_done.length > 0)
        data.job_done_container_image = this.job_done[0].image;
      if (this.container_receipt.length > 0)
        data.container_return_receipt_image = this.container_receipt[0].image;
      if (this.container_return.length > 0)
        data.container_return_image = this.container_return[0].image;

      this.$emit("finish", data);
    },
    saveImageDone(val) {
      this.job_done = val;
    },
    saveImageReturn(val) {
      this.container_return = val;
    },
    saveImageReceipt(val) {
      this.container_receipt = val;
    },

    convertFormatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>

<style scoped></style>
